<template>
  <v-container
    fluid
    class="d-flex flex-column flex-nowrap pa-0"
    style="height: 100%; width: 100%;"
  >
    <v-card
      class="pa-2 secondary"
      tile
      dark
    >
      <v-row
        class="shrink"
        no-gutters
      >
        <v-col
          cols="6"
        >
          <v-select
            v-model="selected"
            :items="reports"
            item-text="name"
            item-value="value"
            label="Tipo"
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          class="text-right align-self-center"
        >
          <v-btn
            class="primary"
            @click="openFilters"
            tile
            large
          >
            <v-icon>mdi-filter</v-icon>Filtrar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row
      class="grow"
      no-gutters
      v-if="!loading"
    >
      <v-col
        cols="12"
      >
        <routes-report
          v-if="selected === 'routes'"
          ref="routes"
          :devices="devices"
          :groups="groups"
        >
        </routes-report>
        <events-report
          v-else-if="selected === 'events'"
          ref="events"
          :devices="devices"
          :groups="groups"
        >
        </events-report>
        <summary-report
          v-else-if="selected === 'summary'"
          ref="summary"
          :devices="devices"
          :groups="groups"
        >
        </summary-report>
        <fuel-report
          v-else-if="selected === 'fuel'"
          ref="fuel"
          :devices="devices"
          :groups="groups"
        >
        </fuel-report>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '@/util/api.service'

export default {
  data() {
    return {
      loading: true,
      filters: {},
      selected: 'routes',
      devices: [],
      groups: [],
      reports: [{
        name: 'Rutas',
        value: 'routes'
      }, {
        name: 'Eventos',
        value: 'events'
      }, {
        name: 'Resumen',
        value: 'summary'
      }, {
        name: 'Gasolina',
        value: 'fuel'
      }]
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const devicesPromise = ApiService({
        url: '/devices',
        method: 'get',
      })
      const groupsPromise = ApiService({
        url: '/groups',
        method: 'get',
      })
      Promise.all([
        devicesPromise,
        groupsPromise,
      ]).then(values => {
        this.devices = values[0]
        // eslint-disable-next-line
        let grupos = []
        if (values && values[1] && Array.isArray(values[1])) {
          values[1].forEach(item => {
            if (item.branch === 'devices') {
              grupos.push(item)
            }
          });
        }
        this.groups = grupos
        this.loading = false
      })
    },
    openFilters() {
      if (this.$refs[this.selected] && this.$refs[this.selected].filtrar) {
        this.$refs[this.selected].filtrar()
      }
    }
  },
  components: {
    'routes-report': () => import('@/components/devicesReports/RoutesReport.vue'),
    'events-report': () => import('@/components/devicesReports/EventsReport.vue'),
    'summary-report': () => import('@/components/devicesReports/SummaryReport.vue'),
    'fuel-report': () => import('@/components/devicesReports/FuelReport.vue')
  }
}
</script>
